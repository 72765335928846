<template>
	<div class="list">
		<!-- 面包屑导航区域 -->
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>订单管理</el-breadcrumb-item>
			<el-breadcrumb-item>订单列表</el-breadcrumb-item>
		</el-breadcrumb>

		<!-- 卡片视图区域 -->
		<el-card>
			<!-- 搜索与添加区域 -->
			<el-row :gutter="20">
				<el-col :span="8">
					<el-input placeholder="请输入取餐号" v-model="searchQuery" clearable @clear="getUserList()">
						<el-button slot="append" icon="el-icon-search" @click="getUserSearch"></el-button>
					</el-input>
				</el-col>
				<el-col :span="4">
				</el-col>
			</el-row>

			<!-- 订单列表区域 -->
			<el-table :data="userlist" border stripe>
				<el-table-column align="center" type="index" :index="getPageIndex" label="序号"
					width="50"></el-table-column>
				<el-table-column align="center" label="点餐用户" prop="tel"></el-table-column>
				<el-table-column align="center" label="订单编号" prop="bianhao" width="150"></el-table-column>
				<el-table-column align="center" label="菜品图片">
					<template slot-scope="scope">
						<img v-if="scope.row.img" class="img" :src="scope.row.img" />
						<span v-else>{{ scope.row.key }}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="标题" prop="title"></el-table-column>
				<el-table-column align="center" label="原料" prop="yuanliao"></el-table-column>
				<el-table-column align="center" label="价格" prop="money"></el-table-column>
				<el-table-column align="center" label="购买数量">
					<template slot-scope="scope">
						<span>x{{scope.row.shopNum}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="单价" prop="money"></el-table-column>
				<el-table-column align="center" label="下单时间" prop="shijian"></el-table-column>
				<el-table-column label="操作" width="200px">
					<template slot-scope="scope">
						<!-- 删除按钮 -->
						<el-button type="danger" icon="el-icon-delete" size="mini"
							@click="removeUserById(scope.row.dingdan_id)"></el-button>
						<!-- 修改按钮 -->
						<el-button type="primary" icon="el-icon-edit" size="mini"
							@click="showEditDialog(scope.row)"></el-button>
						<!-- 删除按钮 -->
						<el-button type="warning" size="mini" v-if="scope.row.is_chucan==0"
							@click="chucan(scope.row.dingdan_id)">出餐</el-button>
						<el-button type="danger" size="mini" v-if="scope.row.is_chucan==1">已出餐</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[5, 10, 15, 20]"  :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>

		</el-card>

		<!-- 修改 -->
		<el-dialog title="修改订单信息" :visible.sync="editDialogVisible" width="66%" @close="editDialogClosed">
			<el-form :model="editForm" ref="editFormRef" label-width="90px">
				<el-form-item label="点餐用户">
					<el-input v-model="editForm.tel" disabled></el-input>
				</el-form-item>
				<el-form-item label="购买数量">
					<el-input v-model="editForm.shopNum"></el-input>
				</el-form-item>
				<el-form-item label="菜品单价">
					<el-input v-model="editForm.money"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editUserInfo">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				userlist: [],
				searchQuery: "", // 搜索
				// 控制修改用户对话框的显示与隐藏
				editDialogVisible: false,
				editForm: [],
				currentPage: 1, // 当前页数
				pageSize: 5,
				total: 0 // 总数据量
			};
		},
		created() {
			this.getUserList();
		},
	
		methods: {
			// 获取所有订单
			handleSizeChange(val) {
				// 切换每页显示数量触发
				console.log(`每页 ${val} 条`);
				 this.pageSize =val;
				this.currentPage = 1; // 切换每页显示数量时，回到第一页
				this.getUserList(); // 重新请求数据
			},
			handleCurrentChange(val) {
				// 切换页数触发
				this.currentPage = val;
				this.getUserList(); // 重新请求数据
			},
			
			//翻页时，确保序号连续
			getPageIndex(index) {
				const page = this.currentPage
				const pagesize = this.pageSize
				// 当前页数 - 1 * 每页数据条数 + index + 1 
				return (page - 1) * pagesize + index + 1
			},
			async getUserList() {
				const {
					data: res
				} = await this.$http.get("getDingdan", {
					params: {
						page: this.currentPage,
						pageSize: this.pageSize // 每页数量
					}
				});
				this.userlist = res.list;
				this.total = res.total; // 设置总数据量
				console.log(res.list);
			},
		
			// 关闭修改对话框
			editDialogClosed() {
				this.$refs.editFormRef.resetFields();
			},
			// 修改
			showEditDialog(obj) {
				console.log(obj);
				this.editForm = obj;
				this.editDialogVisible = true;
			},
			// 删除
			// 根据Id删除对应的用户信息
			async removeUserById(dingdan_id) {
				// 弹框询问用户是否删除数据
				console.log(dingdan_id);
				const confirmResult = await this.$confirm(
					"是否要删除?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}
				).catch((err) => err);

				// 如果用户确认删除，则返回值为字符串 confirm
				// 如果用户取消了删除，则返回值为字符串 cancel
				// console.log(confirmResult)
				if (confirmResult !== "confirm") {
					return this.$message.info("已取消删除");
				}

				const {
					data: res
				} = await this.$http.get("delOrder", {
					params: {
						dingdan_id,
					},
				});
				console.log(res.data);
				this.$message.success("删除成功！");
				this.getUserList();
			},

			// 确认修改
			async editUserInfo() {
				const {
					data: res
				} = await this.$http.get("updateOrder", {
					params: {
						tel: this.editForm.tel,
						shopNum: this.editForm.shopNum,
						money: this.editForm.money,
						dingdan_id: this.editForm.dingdan_id,
					},
				});
				console.log(res);
				// 关闭对话框
				this.editDialogVisible = false;
				this.$message.success("修改成功！");
				this.getUserList();
			},


			// 出餐
			async chucan(dingdan_id) {
				// 弹框询问用户是否删除数据
				const confirmResult = await this.$confirm(
					"出餐前，请先确认是否已经制作完成?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}
				).catch((err) => err);
				if (confirmResult !== "confirm") {
					return this.$message.info("已取消");
				}

				const {
					data: res
				} = await this.$http.get("updateChucan", {
					params: {
						dingdan_id
					},
				});
				console.log(res.data);
				this.$message.success("操作成功！");
				this.getUserList();
			},


			// 搜索
			async getUserSearch() {
				const {
					data: res
				} = await this.$http.get("searchdingdan", {
					params: {
						bianhao: this.searchQuery,
					},
				});
				console.log(res);
				this.userlist = res.list;
				this.total = res.list.length; // 设置总数据量
			},

		},
	};
</script>
<style lang="less" scoped>
	.img {
		width: 120px;
		height: 80px;
	}
</style>